// 防止处理多次点击
function noMultipleClicks(methods, info) {
    let that = this;
    if (that.noClick) {
        that.noClick= false;
        if((info && info !== '') || info ==0) {
            methods(info);
        } else {
            methods();
        }
        setTimeout(()=> {
            that.noClick= true;
        }, 1000)
    } else {}
}
//导出
export default {
    noMultipleClicks
}
